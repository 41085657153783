
const ProjectModel = {

  "AdidasStateSneakers": {
    title:"State Sneakers",
    client:"Adidas",
    category:"Illustration",
    pageUrl:"/adidas-statesneakers",
    imageUrl:"img/homepage/MariaChimishkyan-adidas.jpg",
    altDescription:"Illustration",
    thumbnailUrl:"img/project-index/maria_chimishkyan_adidas_state_sneakers.jpg",
	rolloverColor:"#9d5f48",
    	headline:"Package of illustrations and patterns depicting the most popular Adidas sneakers in every state. A celebratory mood comes together through sprinkled outlines of states, soft symbols representative of different locales, and lively gestural glimmers. ",
		projectName:"State Sneakers",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Nico Mattison",
		contributorLink:"TK",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 	
	
  "Afterfest": {
    title:"Afterfest Making Time",
    client:"Urban Outfitters",
    category:"Animation",
    pageUrl:"/uo-afterfest",
    imageUrl:"img/homepage/16-MariaChimishkyan-UO-Afterfest.jpg",
    altDescription:"Illustration",
    thumbnailUrl:"img/project-index/MariaChimishkyan-UO-Afterfest.gif",
	rolloverColor:"#ffa574",
    	headline:"Posters & stills from animations for Urban Outfitters Afterfest Making Time. Concept inspired by Oskar Schlemmer’s Das Triadische Ballet.",
		projectName:"Afterfest Making Time — NY",
		articleLink:"https://www.electricobjects.com/artists/204/maria-chimishkyan",
		role:"Animator",
		contributorRole:"In Collaboration With",
		contributorName:"Ted Guerrero",
		contributorLink:"https://teddyg.net/",
		
		contributorRole1:"and",
		contributorName1:"Lila Burns",
		contributorLink1:"http://www.lilaburns.com/"
  }, 
  
   "Apple": {
    title:"Best Podcasts of 2021",
    client:"Apple",
    category:"Animation · Illustration",
    pageUrl:"/apple",
    imageUrl:"img/homepage/MariaChimishkyan-Apple.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Apple.jpg",
	rolloverColor:"#FF78A6",
    	headline:"A campaign package celebrating Apple's end of year selection of best podcasts in 2021.",
		projectName:"Best of 2021",
		articleLink:"https://www.apple.com/newsroom/2021/11/apple-podcasts-presents-the-best-of-2021/",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Dora Godfrey",
		contributorLink:"http://doragodfrey.info/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
   "AppleArtText": {
    title:"Art Text",
    client:"Apple",
    category:"Animation",
    pageUrl:"/apple-art-text",
    imageUrl:"img/homepage/MariaChimishkyan-Apple-ArtText.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/maria_chimishkyan_apple_art_text.jpg",
	rolloverColor:"#a47cbd",
    	headline:"Editorial illustration for the App Store showcasing the spirit of Art Text, an app that brings text to life.",
		projectName:"Art Text",
		articleLink:"",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Lynnette Galloway",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
    "Atlantic": {
    title:"Best of 2021",
    client:"The Atlantic",
    category:"Animation · Illustration",
    pageUrl:"/atlantic",
    imageUrl:"img/homepage/MariaChimishkyan-the-atlantic.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-The-Atlantic.jpg",
	rolloverColor:"#ffa574",
    	headline:"A series of animated editorial illustrations to accompany the featured categories of an end of year roundup.",
		projectName:"Best of 2021",
		articleLink:"https://www.theatlantic.com/best-2021-movies-tv-albums-books-podcasts/",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Adam Maida",
		contributorLink:"https://adammaida.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 	
  
  
	"Bandcamp": {
    title: "Music for Plants",
    client:"Bandcamp",
    category:"Animation · Illustration",
    pageUrl:"/bandcamp",
    imageUrl:"img/homepage/MariaChimishkyan-Bandcamp.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bandcamp.gif",
	rolloverColor:"#ffed4a",
    	headline:"A series of animated illustrations for Bandcamp's Music For Plants, an article exploring the different emotional responses of plants to music.",
		projectName:'"Music for Plants"',
		articleLink:"https://daily.bandcamp.com/lists/music-for-plants",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Nicole Ginelli",
		contributorLink:"http://www.nicoleginelli.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
  
  "BandcampExperimental": {
    title: "Five Records Made With Invented Instruments",
    client:"Bandcamp",
    category:"Animation · Illustration",
    pageUrl:"/bandcamp_experimental",
    imageUrl:"img/homepage/MariaChimishkyan-Bandcamp_Experimental.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bandcamp-Experimental.jpg",
	rolloverColor:"#ffed4a",
    	headline:"Depicting the DIY nature of experimental instruments and the different materials & techniques involved in their creation.",
		projectName:"Five Records Made With Invented Instruments",
		articleLink:"https://daily.bandcamp.com/lists/invented-instruments-list",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Josie Keefe",
		contributorLink:"https://josiekeefe.net/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
  
  
  
  
	"BloombergBusinessweek": {
    title: "Misc. Illustrations",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweek.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#f8694c",
    	headline:"",
		projectName:"Avon is Trying to Give Itself a Makeover",
		articleLink:"https://www.bloomberg.com/news/articles/2018-07-18/avon-is-trying-to-give-itself-a-makeover",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Chandra Illick",
		contributorLink:"http://www.chandraillick.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
  
  
	"BloombergBusinessweek1": {
    title: "",
    client:"Bloomberg Businessweek",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:"Europe’s Biggest Luxury Brands Are Nervous About China",
		articleLink:"https://www.bloomberg.com/news/articles/2018-10-18/europe-s-biggest-luxury-brands-are-nervous-about-china",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Chandra Illick",
		contributorLink:"http://www.chandraillick.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
  
  
	"BloombergBusinessweek2": {
    title: "",
    client:"Bloomberg Businessweek",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:'"Apps Are Coming for Your Happy Hour"',
		articleLink:"https://www.bloomberg.com/news/articles/2018-05-23/apps-are-coming-for-your-happy-hour",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Chandra Illick",
		contributorLink:"http://www.chandraillick.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
  
  
	"BloombergBusinessweek3": {
    title: "",
    client:"Bloomberg Businessweek",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:"Google Is Training Machines to Predict When a Patient Will Die",
		articleLink:"https://www.bloomberg.com/news/articles/2018-06-18/google-is-training-machines-to-predict-when-a-patient-will-die",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Steph Davidson",
		contributorLink:"https://paralleluniver.se/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
  
	"BloombergBusinessweek4": {
    title: "Misc. Illustrations",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweek.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#8fe146",
    	headline:"",
		projectName:"Spotify Bets Big on Podcasts as a Path to Profitability",
		articleLink:"https://www.bloomberg.com/news/features/2021-01-12/spotify-spot-views-joe-rogan-more-podcasts-as-path-to-profitability",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Alexander Shoukas",
		contributorLink:"http://alexandershoukas.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },    
  
    "BloombergBusinessweek5": {
    title: "Misc. Illustrations",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweek-TitansofJunk.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#685F63",
    	headline:"Miscellaneous editorial illustrations completed for Bloomberg Businessweek over the years, online and in print.",
		projectName:'"Titans of Junk"',
		articleLink:"https://www.bloomberg.com/news/articles/2022-02-03/netflix-tesla-t-mobile-and-dell-turned-junk-debt-into-bond-market-treasure",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Chandra Illick",
		contributorLink:"http://www.chandraillick.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
  
    "BloombergBusinessweek6": {
    title: "The Dangers of the Data Trade",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweek-DataBrokers.png",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#B87CB7",
    	headline:"",
		projectName:"The Dangers of the Data Trade",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Saxton Randolph",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
    "BloombergBusinessweek7": {
    title: "Real Estate Recovery?",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek",
    imageUrl:"img/homepage/MariaChimishkyan-Bloomberg.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#B87CB7",
    	headline:"",
		projectName:"Real Estate Recovery?",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Jordi Ng",
		contributorLink:"https://awpoop.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
   
    
	"BloombergBusinessweekCover": {
    title: "Are We There Yet?",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek-cover",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweekCover.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bloomberg-Businessweek.jpg",
	rolloverColor:"#92c2b9",
    	headline:"Cover illustration and inside spread for the November 2nd issue of Bloomberg Businessweek, spotlighting Operation Warp Speed's sprint towards a viable vaccine.",
		projectName:"Are We There Yet?",
		articleLink:"https://www.bloomberg.com/magazine/businessweek/20_46",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Alexander Shoukas",
		contributorLink:"http://alexandershoukas.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 

	"BloombergBusinessweekCurrencies": {
    title: "Can't Stop Won't Stop",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek-currencies",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweekCurrencies.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bloomberg-Businessweek.jpg",
	rolloverColor:"#353336",
    	headline:"Cover illustration for the October 3rd issue of Bloomberg Businessweek spotlighting the collapse of the Euro, Pound & Yen amid the surge of the dollar.",
		projectName:"Cant Stop Wont Stop",
		articleLink:"https://www.bloomberg.com/magazine/businessweek/22_42",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Chandra Illick",
		contributorLink:"chandraillick.com",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  

	"BloombergBusinessweekFeature": {
    title: "Inside Operation Warp Speed’s $18 Billion Sprint for a Vaccine",
    client:"Bloomberg Businessweek",
    category:"Illustration",
    pageUrl:"/bloomberg-businessweek-cover",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergBusinessweekCover.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-BloombergBusinessweek.gif",
	rolloverColor:"#92c2b9",
    	headline:"",
		projectName:"Inside Operation Warp Speed’s $18 Billion Sprint for a Vaccine",
		articleLink:"https://www.bloomberg.com/news/features/2020-10-29/inside-operation-warp-speed-s-18-billion-sprint-for-a-vaccine",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Alexander Shoukas",
		contributorLink:"http://alexandershoukas.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },
  
 	"BloombergGreen": {
    title: "The Tech Tracking Down Methane Leaks",
    client:"Bloomberg Green",
    category:"Illustration",
    pageUrl:"/bloomberg-green",
    imageUrl:"img/homepage/MariaChimishkyan-BloombergGreen.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bloomberg-Green.jpg",
	rolloverColor:"#ac5d7c",
    	headline:"Series of spot illustrations showcasing the various methods and devices used to track methane leaks.",
		projectName:"The Tech Tracking Down Methane Leaks",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Erin Knutson",
		contributorLink:"http://www.erinknutson.studio/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },
  

  "Bulletin": {
    title: "Brand Identity",
    client:"Bulletin",
    category:"Art Direction · Design",
    pageUrl:"/Bulletin-BrandIdentity",
    imageUrl:"img/homepage/MariaChimishkyan-Bulletin-BrandIdentity.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bulletin-BrandIdentity.jpg",
	rolloverColor:"#fff700",
    	headline:"Bulletin is a wholesale platform that connects retailers to small brands. I was brought in-house by them to ease the transition from their B2C origins into their new B2B future. Through the introduction of neutral tones, a versatile type system and polished layouts across all touchpoints their initial brand identity was gradually expanded into a broader Pewaewsystem. They were able to reach a wider audience while still championing the core values that started everything.",
		projectName:"Brand Identity",
		articleLink:"",
		role:"Art Director",
		contributorRole:"Photography",
		contributorName:"Luca Venter",
		contributorLink:"https://lucaventer.com/",
		
		contributorRole1:"Photography",
		contributorName1:"Ryan Duffin",
		contributorLink1:"https://ryan-duffin.com/"
		
	
  },  
  
  
  "BulletinLWY": {
    title: "Lots Without You",
    client:"Bulletin",
    category:"Art Direction · Design",
    pageUrl:"/bulletin-lotswithoutyou",
    imageUrl:"img/homepage/MariaChimishkyan-Bulletin-LotsWithoutYou.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bulletin-LotsWithoutYou.png",
	rolloverColor:"#fca3d5",
    	headline:"Valentine's Day campaign inspired by a twist on the words Lost Without You",
		projectName:"Lots Without You",
		articleLink:"",
		role:"Art Director",
		contributorRole:"Photography",
		contributorName:"Luca Venter",
		contributorLink:"https://lucaventer.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  

  "BulletinRetailerNetwork": {
    title: "Retailer Network",
    client:"Bulletin",
    category:"Art Direction · Graphic Design",
    pageUrl:"/bulletin-retailer-network",
    imageUrl:"img/homepage/MariaChimishkyan-Bulletin-RN.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bulletin-RN.png",
	rolloverColor:"#a9f39f",
    	headline:"Promotional mailer as Bulletin shifted from being a brick & mortar retail operation to a retailer network platform connecting brands to retailers and optimizing wholesale.",
		projectName:"Mailer",
		articleLink:"",
		role:"Art Director",
		contributorRole:"PHOTOGRAPHY",
		contributorName:"RYAN DUFFIN",
		contributorLink:"https://ryan-duffin.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },    
  
  
  "BulletinSwag": {
    title: "Spring & Summer Merchandise",
    client:"Bulletin",
    category:"Merchandise Design",
    pageUrl:"/bulletin-swag",
    imageUrl:"img/homepage/MariaChimishkyan-Bulletin-Swag.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bulletin-Swag.gif",
	rolloverColor:"#b399ff",
    	headline:"Spring and summer pride merchandise. ",
		projectName:"Spring & Summer Swag",
		articleLink:"",
		role:"Art Director",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""		

  },  
  
  
  "Bushwig2019": {
    title: "Berlin & NY 2019",
    client:"Bushwig",
    category:"Event Identity",
    pageUrl:"/bushwig-2019",
    imageUrl:"img/homepage/MariaChimishkyan-Bushwig2019.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bushwig2019.png",
	rolloverColor:"#ff2b1c",
    	headline:"Identity, posters and misc. social assets for Bushwig NYC 2019.",
		projectName:"Berlin & NY 2019",
		articleLink:"",
		role:"Illustrator & Animator",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },

  
  "Bushwig2018": {
    title: "Bushwig 2018",
    client:"Bushwig",
    category:"Animation · Event Identity · Illustration",
    pageUrl:"/Bushwig-2018",
    imageUrl:"img/homepage/MariaChimishkyan-Bushwig-2018.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bushwig-2018.gif",
	rolloverColor:"#ffe436",
    	headline:"Identity, posters and misc. social assets for Bushwig NYC 2018.",
		projectName:"Bushwig 2018",
		articleLink:"",
		role:"Illustrator & Animator",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
   
  
  "BushwigBerlin2018": {
    title: "Bushwig Berlin 2018",
    client:"Bushwig",
    category:"Illustration",
    pageUrl:"/bushwig-berlin-2018",
    imageUrl:"img/homepage/MariaChimishkyan-Bushwig-Berlin-2018.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Bushwig-Berlin-2018.png",
	rolloverColor:"#ff4722",
    	headline:"Identity, posters and misc. social assets for Bushwig Berlin 2018, a 24-hour drag & dance music extravaganza.",
		projectName:"Bushwig Berlin 2018",
		articleLink:"",
		role:"Illustrator & Animator",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  "CashApp": {
    title: "Treasure Hunt",
    client:"Cash App",
    category:"Illustration",
    pageUrl:"/cash-app-ISpy",
    imageUrl:"img/homepage/MariaChimishkyan-CashApp.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-CashApp.jpg",
	rolloverColor:"#c2e942",
    	headline:"A treasure hunt with an accompanying caption: Three ball point pens, a loosened necktie, three blue dollar signs, one eight sided die. Two weighted barbells, some coins stacked up high, and when you've found those it's over, you've done it, goodbye.",
		projectName:"Treasure Hunt",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Claire Merchlinsky",
		contributorLink:"https://clairemerchlinsky.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 

  "CashAppCashTruths": {
    title: "Cash Truths",
    client:"Cash App",
    category:"Animation",
    pageUrl:"/cash-app-cash-truths",
    imageUrl:"img/homepage/MariaChimishkyan-CashApp-CashTruths.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-CashApp-CashTruths.jpg",
	rolloverColor:"#283dc3",
    	headline:"Ambient animations accompanying meditations on cash.",
		projectName:"Cash Truths",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Claire Merchlinsky",
		contributorLink:"https://clairemerchlinsky.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
 
  "ElectricObjects": {
    title: "Infinite Play",
    client:"Electric Objects",
    category:"Animation",
    pageUrl:"/electric-objects",
    imageUrl:"img/homepage/MariaChimishkyan-ElectricObjects.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-ElectricObjects.jpg",
	rolloverColor:"#f2e150",
    	headline:"A commissioned collection of animations for Electric Objects exploring the topic of \"Infinite Play\". The modular & infinitely expandable visual components of playgrounds reflect the often self indulgent and infinite nature of graphic play and personal projects.",
		projectName:'"Infinite Play"',
		articleLink:"https://www.electricobjects.com/artists/204/maria-chimishkyan",
		role:"Animator",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },    
   

  "Elsewhere": {
    title: "Misc. Animations",
    client:"Elsewhere",
    category:"Animation",
    pageUrl:"/elsewhere",
    imageUrl:"img/homepage/MariaChimishkyan-Elsewhere.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Elsewhere.gif",
	rolloverColor:"#ff7bdc",
    	headline:"Abstract animations created to promote various events at Elsewhere.",
		projectName:"Misc. Animations",
		articleLink:"",
		role:"Animator",
		contributorRole:"With",
		contributorName:"Eye Bodega",
		contributorLink:"https://eyebodega.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  
  "GIPHYArts": {
    title: "GIPHYArts",
    client:"GIPHY",
    category:"Animation",
    pageUrl:"/giphy-arts",
    imageUrl:"img/homepage/MariaChimishkyan-GIPHYArts.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-GIPHYArts.gif",
	rolloverColor:"#ff7bdc",
    	headline:"Surreal promotional gif for GIPHYArts.",
		projectName:"Promotional gif",
		articleLink:"",
		role:"Animator",
		contributorRole:"With",
		contributorName:"Dani Newman",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
  "HighSnobiety": {
    title: "The Plastic Surgery Paradigm",
    client:"High Snobiety",
    category:"Illustration",
    pageUrl:"/high-snobiety",
    imageUrl:"img/homepage/MariaChimishkyan-HighSnobiety-PlasticSurgery.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-HighSnobiety-PlasticSurgery.jpg",
	rolloverColor:"#ff7bdc",
    	headline:"A bag spill of various accessories, jewelry, and plastic surgery tools among other things. The casual mix of these disparate items underscores the growing ubiquity of plastic surgery in the lives of younger generations. This piece appeared in print and online in the summer 2023 issue of The High Snobiety Magazine.",
		projectName:"The Plastic Surgery Paradigm",
		articleLink:"https://www.highsnobiety.com/p/gen-z-plastic-surgery-cosmetic-procedures/",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Matteo Mobilio",
		contributorLink:"http://www.matteomobilio.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
  "Microsoft": {
    title: "Experiments in Office Flexibility",
    client:"Microsoft",
    category:"Illustration",
    pageUrl:"/microsoft",
    imageUrl:"img/homepage/MariaChimishkyan-Microsoft-Experimentation.png",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#ff7bdc",
    	headline:"Exploring the flexibility of remote work during the pandemic and how that can be brought back to in person office dynamics.",
		projectName:"Experiments in Office Flexibility",
		articleLink:"",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Kassandra Zuanich",
		contributorLink:"https://kaslon.pro/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  

  "LATimesClimateChange": {
    title: "Finding clean air in Los Angeles? It’s an almost unsolvable puzzle.",
    client:"LA Times",
    category:"Illustration",
    pageUrl:"/latimes-climatechange",
    imageUrl:"img/homepage/MariaChimishkyan-LATimes.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-LATimes.jpg",
	rolloverColor:"#ee8d4c",
    	headline:"A jumbled puzzle of iconic Los Angeles motifs shifting around in space. The confusion of these interlocking pieces visually reflects the challenge of finding clean air in the city.",
		projectName:"Finding clean air in Los Angeles? It’s an almost unsolvable puzzle.",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Allison Hong",
		contributorLink:"https://allimyhdesign.tumblr.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   

 
  "LuckyPeach": {
    title: "Big Bad Breakfast",
    client:"Lucky Peach",
    category:"Illustration",
    pageUrl:"/lucky-peach",
    imageUrl:"img/homepage/MariaChimishkyan-LuckyPeach.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-LuckyPeach.png",
	rolloverColor:"#cf66ff",
    	headline:"Decadent illustrations for Lucky Peach's online review of “Big Bad Breakfast”. Hint: one of these has a hidden face.",
		projectName:'"Big Bad Breakfast"',
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Rob Engvall",
		contributorLink:"http://www.robengvall.net/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
   
  
  "LuckyPeach1": {
    title: "Big Bad Breakfast",
    client:"Lucky Peach",
    category:"Illustration",
    pageUrl:"/lucky-peach",
    imageUrl:"img/homepage/MariaChimishkyan-LuckyPeach.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-LuckyPeach.png",
	rolloverColor:"#cf66ff",
    	headline:"Nine chickens illustrated for Lucky Peach's printed Cockfight feature in issue #22, covering wild breeds of chicken found all over the world. Inspired by a combination of early video game bosses and each chicken’s colorful bio.",
		projectName:'"Cock fight"',
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Editorial Design & Art Direction",
		contributorName:"Rob Engvall",
		contributorLink:"http://www.robengvall.net/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
   "LuxMagazine": {
    title: "Consciousness Raising with Corporations",
    client:"Lux Magazine",
    category:"Illustration",
    pageUrl:"/lux-magazine",
    imageUrl:"img/homepage/MariaChimishkyan-LuxMagazine.jpg",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#fc55a4",
    	headline:"Accompanying illustration for a piece about the bizarre world of corporate feminism.",
		projectName:"Consciousness Raising with Corporations",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Sharanya Durvasula",
		contributorLink:"",
		
		contributorRole1:"Editorial Design",
		contributorName1:"Chloe Scheffe",
		contributorLink1:"https://chloescheffe.github.io/"
  },  
 
 
  
  "Medium": {
    title: "Misc. Illustrations",
    client:"Medium",
    category:"Illustration",
    pageUrl:"/medium",
    imageUrl:"img/homepage/MariaChimishkyan-Medium.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Medium.gif",
	rolloverColor:"#ffffff",
    	headline:"Misc. editorial illustrations for Medium articles & columns.",
		projectName:"Designers are imagining video games without guns",
		articleLink:"https://onezero.medium.com/its-time-to-take-the-guns-out-of-video-games-10c7e01869e5",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Noah Baker",
		contributorLink:"https://noahbaker.work/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },


  "Medium1": {
    title: "",
    client:"Medium",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:"Carbo-Loading is Dead. Long Live Carbs!",
		articleLink:"https://elemental.medium.com/is-carbo-loading-still-a-thing-7e1f9e357d84",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Dora Godfrey",
		contributorLink:"http://doragodfrey.info/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },


  "Medium2": {
    title: "",
    client:"Medium",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:"The Future of Fertility",
		articleLink:"https://onezero.medium.com/at-the-fringes-of-fertility-tech-2d3bb749bc65",
		role:"Illustrator",
		contributorRole:"Editorial design & art direction",
		contributorName:"Ryan Hubbard",
		contributorLink:"http://ryanjhubbard.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },
  
   
  "Medium3": {
    title: "",
    client:"Medium",
    category:"",
    pageUrl:"",
    imageUrl:"",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"",
    	headline:"",
		projectName:"Future Humans",
		articleLink:"https://onezero.medium.com/will-humanity-be-better-off-in-2118-d1b2b44cd998",
		role:"Illustrator",
		contributorRole:"Editorial design & art direction",
		contributorName:"Ryan Hubbard",
		contributorLink:"http://ryanjhubbard.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  
  "MTV": {
    title: "Misc. Projects",
    client:"MTV",
    category:"Animation",
    pageUrl:"/mtv",
    imageUrl:"img/homepage/MariaChimishkyan-MTV.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-MTV.gif",
	rolloverColor:"#fc3734",
    	headline:"Evergreen channel bumper/ident, aired between shows & commercial breaks.",
		projectName:"Evergreen channel bumper/ident",
		articleLink:"",
		role:"Animator",
		contributorRole:"Creative Direction",
		contributorName:"Richard Turley",
		contributorLink:"https://richardturley.tumblr.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  "NewYorker": {
    title:"The Obsessive Pleasures of Mechanical-Keyboard Tinkerers",
    client:"The New Yorker",
    category:"Animation · Illustration",
    pageUrl:"/newyorker",
    imageUrl:"img/homepage/MariaChimishkyan-the-new-yorker.gif",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#facc6a",
    	headline:"Exploring the impressive customizations and flights of fancy that mechanical keyboards offer.",
		projectName:"The Obsessive Pleasures of Mechanical-Keyboard Tinkerers",
		articleLink:"https://www.newyorker.com/tech/annals-of-technology/the-obsessive-pleasures-of-mechanical-keyboard-tinkerers",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Nicholas Konrad",
		contributorLink:"https://www.nicholaskonrad.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  
  "NewYorker_BitterPill": {
    title:"Bitter Pill",
    client:"The New Yorker",
    category:"Animation · Illustration",
    pageUrl:"/newyorker-bitter-pill",
    imageUrl:"img/homepage/MariaChimishkyan-TheNewYorker-BitterPill.jpg",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#487b8e",
    	headline:"For an article about patient-advocacy groups that mobilized to expedite access to Relyvrio, a powdered drug designed for ALS treatment. The piece sheds light on the broader consequences of hasty FDA approvals. Published in the print edition of the June 26, 2023 issue of the New Yorker.",
		projectName:"Bitter Pill",
		articleLink:"https://www.newyorker.com/magazine/2023/06/26/relyvrio-als-fda-approval",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Nicholas Blechman",
		contributorLink:"https://www.nicholasblechman.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
      
  
  
  "NewYorker_MDMA": {
    title:"MDMA Research",
    client:"The New Yorker",
    category:"Animation · Illustration",
    pageUrl:"/newyorker-mdma",
    imageUrl:"img/homepage/MariaChimishkyan-TheNewYorker-MDMA.jpg",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#facc6a",
    	headline:"Exploring MDMA research, and the different environments and moods in which MDMA makes an appearance these days.",
		projectName:'"MDMA Research"',
		articleLink:"TK",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Nicholas Konrad",
		contributorLink:"https://www.nicholaskonrad.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
    
  
  
  "Nike": {
    title:"Birthday Animation",
    client:"Nike",
    category:"Animation · Illustration",
    pageUrl:"/nike",
    imageUrl:"img/homepage/MariaChimishkyan-Nike.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Nike.jpg",
	rolloverColor:"#fffd3e",
    	headline:"Celebratory birthday animations with surprise twists for online channels & in-store.",
		projectName:"Birthday Animation",
		articleLink:"",
		role:"Animator",
		contributorRole:"Creative Direction",
		contributorName:"Anyways Creative",
		contributorLink:"https://www.anyways.co/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  
  "NYTimes": {
    title:"When Gamers Become Parents, Finding Balance is Next Level",
    client:"The New York Times",
    category:"Animation · Illustration",
    description:"When Gamers Become Parents",
    pageUrl:"/nytimes",
    imageUrl:"img/homepage/MariaChimishkyan-NYTimes.gif",
    altDescription:"When Gamers Become Parents.",
    thumbnailUrl:"img/project-index/MariaChimishkyan-NYTimes.gif",
    rolloverColor:"#ec5929",
    	headline:"Depicting a balance of activities for parents who continue to be gamers after having children.",
		projectName:"When Gamers Become Parents, Finding Balance is Next Level",
		articleLink:"https://www.nytimes.com/2020/04/17/parenting/video-games-parents.html",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Deanna Donegan",
		contributorLink:"http://www.deannadonegan.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
   
   
   
    "NYTimesQuestionnaire": {
    title:"Fever? Sore Throat? They Just Check ‘No’",
    client:"The New York Times",
    category:"Illustration",
    description:"Fever? Sore Throat? They Just Check ‘No’",
    pageUrl:"/nytimes-questionnaire",
    imageUrl:"img/homepage/MariaChimishkyan-NYTimes.jpg",
    altDescription:"Fever? Sore Throat? They Just Check ‘No’",
    thumbnailUrl:"img/project-index/MariaChimishkyan-NYTimes.jpg",
    rolloverColor:"#FDB070",
    	headline:"On the multitudes of people fibbing whilst filling out covid questionnaires.",
		projectName:"Fever? Sore Throat? They Just Check ‘No’",
		articleLink:"https://www.nytimes.com/2022/01/31/fashion/covid-form-fibs.html",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Elana Schlenker",
		contributorLink:"https://elanaschlenker.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
   
  "NYTimesRightToBeauty": {
    title:"In Brazil, Beauty Is a Right. Are They On to Something?",
    client:"The New York Times",
    category:"Illustration",
    description:"In Brazil, Beauty Is a Right. Are They On to Something?",
    pageUrl:"/nytimes-right-to-beauty",
    imageUrl:"img/homepage/MariaChimishkyan-nytimes-RightToBeauty.jpg",
    altDescription:"In Brazil, Beauty Is a Right. Are They On to Something?",
    thumbnailUrl:"img/project-index/MariaChimishkyan-nytimes-RightToBeauty.jpg",
    rolloverColor:"#565d53",
    	headline:"For an essay detailing an experience with plastic surgery outside of the US. The piece explores the power that comes with controlling one’s appearance.",
		projectName:"In Brazil, Beauty Is a Right. Are They On to Something?",
		articleLink:"https://www.nytimes.com/2023/07/15/opinion/do-we-have-a-right-to-beauty.html",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Shoshana Schultz",
		contributorLink:"https://shoshanaschultz.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  
  "NYTimesSupplements": {
    title:"How Useful Are Supplements?",
    client:"The New York Times",
    category:"Illustration",
    description:"How Useful Are Supplements?",
    pageUrl:"/nytimes-supplements",
    imageUrl:"img/homepage/MariaChimishkyan-NYTimes.jpg",
    altDescription:"Supplements",
    thumbnailUrl:"img/project-index/MariaChimishkyan-NYTimes.gif",
    rolloverColor:"#D1B0A1",
    	headline:"An interactive quiz on the usefulness of various supplements.",
		projectName:'"How Useful Are Supplements?"',
		articleLink:"https://www.nytimes.com/interactive/2022/06/15/well/supplements-effectiveness-quiz.html",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Deanna Donegan",
		contributorLink:"http://www.deannadonegan.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
     
   
   
  "RBMA": {
    title:"Educational Modules",
    client:"Red Bull Music Academy",
    category:"Animation · Illustration",
    pageUrl:"/rbma",
    imageUrl:"img/homepage/MariaChimishkyan-rbma.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-rbma.png",
	rolloverColor:"#ff9ad3",
    	headline:"Animated weekly illustrations for Red Bull Music Academy’s Educational Modules.",
		projectName:"Educational Modules",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Creative Direction",
		contributorName:"Johannes Ammler",
		contributorLink:"http://www.johannesammler.de/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""

  },  
   

  "Splice": {
    title:"Editorial Illustrations",
    client:"Splice",
    category:"Illustration",
    pageUrl:"/splice",
    imageUrl:"img/homepage/MariaChimishkyan-Splice.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Splice.png",
	rolloverColor:"#ffe436",
    	headline:"Editorial illustrations",
		projectName:"Optimizing Serum for Better CPU Efficiency",
		articleLink:"https://splice.com/blog/optimizing-serum-cpu-efficiency/",
		role:"Animator",
		contributorRole:"Art Direction",
		contributorName:"Emily Haasch",
		contributorLink:"http://www.emilyhaasch.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
  

  "SpaceNinety8": {
    title:"Space Ninety 8",
    client:"Urban Outfitters",
    category:"Art Direction · Stop Motion",
    pageUrl:"/uo-space-ninety-8",
    imageUrl:"img/homepage/MariaChimishkyan-UO-SpaceNinety8.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-UO-SpaceNinety8.gif",
	rolloverColor:"#ee8443",
    	headline:"Stop motion compositions celebrating the construction of Urban Outfitters Space Ninety 8 in Williamsburg & building up excitement for its opening day.",
		projectName:"Space Ninety 8",
		articleLink:"",
		role:"Designer",
		contributorRole:"Art Direction",
		contributorName:"Namik Schwarz",
		contributorLink:"https://namiks.tumblr.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
     
  
  
  "Spotify": {
    title:"Science of Sound",
    client:"Spotify",
    category:"Animation",
    pageUrl:"/spotify",
    imageUrl:"img/homepage/MariaChimishkyan-Spotify.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Spotify.jpg",
	rolloverColor:"#9885b6",
    	headline:"Part of a campaign for Spotify Premium with a focus on delivering music in its purest form. Devices for listening to music appear crystal clear, welcoming the viewer into their inner workings.",
		projectName:"Science of Sound",
		articleLink:"",
		role:"Animator",
		contributorRole:"Creative Direction",
		contributorName:"Byte",
		contributorLink:"https://bytemissioncontrol.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },   
    
  
  "TheBaffler": {
    title:"Consolation Prizes",
    client:"The Baffler",
    category:"Illustration",
    pageUrl:"/the-baffler",
    imageUrl:"img/homepage/MariaChimishkyan-TheBaffler.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-TheBaffler.png",
	rolloverColor:"#687ff1",
    	headline:"Depicting how the lowering of prices for things such as consumer electronics hasn’t solved the issues of inequality or quality of life as was promised.",
		projectName:"Consolation Prizes",
		articleLink:"https://thebaffler.com/salvos/consolation-prizes-pareene",
		role:"Illustrator",
		contributorRole:"Art Direction",
		contributorName:"Lindsay Ballant",
		contributorLink:"http://www.lindsayballant.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
   "TheVerge": {
    title: "How Twine Remade Gaming",
    client:"The Verge",
    category:"Animation · Illustration",
    pageUrl:"/the-verge",
    imageUrl:"img/homepage/MariaChimishkyan-TheVerge.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-TheVerge.jpg",
	rolloverColor:"#687ff1",
    	headline:"Series of animated spot illustrations focusing on Twine and its culture of weird text based adventure games. These lo-fi games serve as easily accessible avenues for empathetic experiences.",
		projectName:"Text Adventures: How Twine Remade Gaming",
		articleLink:"https://www.theverge.com/22321816/twine-games-history-legacy-art",
		role:"Illustrator",
		contributorRole:"Creative Director",
		contributorName:"William Joel",
		contributorLink:"https://billiamjoel.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },  
  
   "TheVergeOfflineActivities": {
    title:"Offline Activities",
    client:"The Verge",
    category:"Illustration",
    pageUrl:"/the-verge-offline-activities",
    imageUrl:"img/homepage/MariaChimishkyan-TheVerge.jpg",
    altDescription:"",
    thumbnailUrl:"",
	rolloverColor:"#363731",
    	headline:"Two spreads with illustrations spotlighting technology, apps, hardware, and trends of the past. Part of a zine celebrating The Verge's 10th year anniversary.",
		projectName:"Offline Activities",
		articleLink:"",
		role:"Illustrator",
		contributorRole:"Creative Director",
		contributorName:"Elana Schlenker",
		contributorLink:"https://elanaschlenker.com/",
		
		contributorRole1:"Art Direction",
		contributorName1:"Jordi Ng",
		contributorLink1:"https://awpoop.com/"
  },  
  
      
  "UsluAirlines": {
    title:"Uslu Airlines Lookbook",
    client:"Urban Outfitters",
    category:"Design",
    pageUrl:"/uo-uslu-airlines",
    imageUrl:"img/homepage/MariaChimishkyan-UO-UsluAirlines.gif",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-UO-UsluAirlines.gif",
	rolloverColor:"#ffb38a",
    	headline:"Uslu Airlines Beauty Lookbook for Urban Outfitters. Comprised of collaged, scanned and hand drawn components which were created using the colorful nail polish & lipstick featured in the lookbook.",
		projectName:"Uslu Airlines Lookbook",
		articleLink:"",
		role:"Designer",
		contributorRole:"",
		contributorName:"",
		contributorLink:"",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 
 
 
  "WSJ": {
    title:"Fewer Choices for Shoppers this Season",
    client:"The Wall Street Journal",
    category:"Illustration",
    pageUrl:"/wsj",
    imageUrl:"img/homepage/MariaChimishkyan-WallStreetJournal.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-wsj.jpg",
	rolloverColor:"#4460b9",
    	headline:"Retailers are starting to provide customers with less options for their products, based off of research that shows too many choices can overwhelm buyers. ",
		projectName:"Fewer Choices for Shoppers this Season",
		articleLink:"",
		role:"Designer",
		contributorRole:"Art Direction",
		contributorName:"Lynne Carty",
		contributorLink:"http://lynnecarty.info/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  }, 

  
   "Wired": {
    title:"Save the Metaverse",
    client:"Wired",
    category:"Illustration",
    pageUrl:"/wired",
    imageUrl:"img/homepage/MariaChimishkyan-Wired-SaveTheMetaverse.jpg",
    altDescription:"",
    thumbnailUrl:"img/project-index/MariaChimishkyan-Wired-SaveTheMetaverse.jpg",
	rolloverColor:"#4460b9",
    	headline:"An illustration for Wired Magazine around the ethics of the metaverse and potential it holds in its nascent stage. Can the people building it now avoid repeating the mistakes of the past?",
		projectName:"Save the Metaverse",
		articleLink:"",
		role:"Designer",
		contributorRole:"Art Direction",
		contributorName:"Anjali Nair",
		contributorLink:"https://anjali-nair.com/",
		
		contributorRole1:"",
		contributorName1:"",
		contributorLink1:""
  },      
  
  "YoungGuns": {
    title:"Visual Identity",
    client:"Young Guns 15",
    category:"Visual Identity",
    pageUrl:"/youngguns",
    imageUrl:"img/homepage/MariaChimishkyan-YoungGuns.jpg",
    altDescription:"Identity, animations, physical award and printed collateral.",
    thumbnailUrl:"img/project-index/MariaChimishkyan-YoungGuns.png",
    rolloverColor:"#e7c0f1",
    	headline:"During my time at RoAndCo, our studio was selected to develop the identity for Young Guns 15. At a whirlwind pace we developed numerous ideas and approaches, finally landing on one that captures the excitement and possibility that the award represents for its recipients.",
		projectName:"Visual Identity",
		articleLink:"",
		role:"Senior Designer",	
		contributorRole:"Studio",
		contributorName:"RoAndCo Studio",
		contributorLink:"http://www.roandcostudio.com/#0",
		
		contributorRole1:"Creative Direction",
		contributorName1:"Roanne Adams",
		contributorLink1:""
  },
  

}

export default ProjectModel;
