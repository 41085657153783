import React, { Component } from "react";
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import './App.css';



class Nav extends Component {
  state = {
    bg: "transparent"
  };

  listenScrollEvent = e => {
    if ((window.scrollY < window.innerHeight) && (this.props.coverPresent==="yes")) {
      this.setState({ bg: "rgba(0, 0, 0, 0)" });
    } else {
      this.setState({ bg: "rgba(0, 0, 0, 1)" });
    }
};

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  componentDidUpdate(prevProps){
    if(prevProps.coverPresent !== this.props.coverPresent){
      if (this.props.coverPresent==="yes") {
        this.setState({ bg: "rgba(0, 0, 0, 0)" });
      } else {
        this.setState({ bg: "rgba(0, 0, 0, 1)" });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (


      <div className="nav" style={{backgroundColor: [this.state.bg]}} >
	  		<div className="inner-wrapper">
				<Container>
				<div className="nav-inner">

					<Row>

						<Col xs={9} sm={9}>
							<div className="navleft">
							<Link to= {"/"} className="item">Maria Chimish<span style={{letterSpacing:'0.3px'}}>k</span>yan</Link>
							</div>
						</Col>



						<Col xs={3} sm={3}>
							<div className="navright">
							<Link to= {"/info"} className="item">Info</Link>
							</div>
						</Col>

					</Row>

				</div>
				</Container>
			</div>
			</div>

    );
  }
}

const mapStateToProps = (state) => {
  return { coverPresent: state.coverPresent };
};


export default connect(mapStateToProps)(Nav);
