import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Bushwig2018({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">
	
	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.Bushwig2018.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-1.gif" alt="Bushwig 2018 — Maria Chimishkyan 1"/>

	<div className="inner-wrapper">
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.Bushwig2018}/>
	    </Container>
		
		<div className="project-section">
		    <Container>
		    	<Row>
					<Col sm={3}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-2.jpg" alt="Bushwig 2018 — Maria Chimishkyan 2"/>
					<div className="project-caption">NYC Poster<br/>(Colorway A)</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-3.jpg" alt="Bushwig 2018 — Maria Chimishkyan 3"/>
					<div className="project-caption">NYC Poster<br/>(Colorway B)</div>
					</Col>

					<Col sm={3}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-4.jpg" alt="Bushwig 2018 — Maria Chimishkyan 4"/>
					<div className="project-caption">Social</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-5.jpg" alt="Bushwig 2018 — Maria Chimishkyan 5"/>
					<div className="project-caption">Social</div>
					</Col>
				</Row>
				
			</Container>
		</div>
	
		
	
		<div className="project-section">
		    <Container>
		    	<Row>
					<Col sm={6}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-6.png" alt="Bushwig 2018 — Maria Chimishkyan 6"/>
					<div className="project-caption">Video Still</div>
					</Col>

					<Col sm={6}>
					<img src= "img/project-page/bushwig-2018/bushwig-2019-MariaChimishkyan-6.png" alt="Bushwig 2018 — Maria Chimishkyan 6"/>
					<div className="project-caption">Video Still</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Bushwig2018);
