import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BulletinSwag({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">
	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.BulletinSwag.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bulletin-swag/bulletin-swag_MariaChimishkyan-1.jpg" alt="Bulletin — Swag — Maria Chimishkyan 1"/>

	<div className="inner-wrapper">
		<Container>
			<ProjectDescription projectModel={ProjectModel.BulletinSwag}/>
		</Container>
	
		<div className="project-section">
			<Container>
				<Row>
					<Col sm={6}>
						<img src= "img/project-page/bulletin-swag/bulletin-swag_MariaChimishkyan-2.gif" alt="Bulletin — Swag — Maria Chimishkyan 2"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
						<div className="project-headline">The Bulletin logo reimagined as a monochromatic rainbow in honor of Pride Month. This special iteration appeared in promos and on a limited edition lime-colored coach jacket.</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>

	<ProjectIndex/>
</div>
  )
}

export default connect()(BulletinSwag);
