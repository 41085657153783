import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Apple({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.Apple.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={2}>
				</Col>
				
	    		<Col sm={8}>
	    		<img src= "img/project-page/apple/apple-MariaChimishkyan-1.jpg" alt="Apple — Maria Chimishkyan 1"/>
				</Col>
				<Col sm={2}>
				</Col>
			</Row>
			 <ProjectDescription projectModel={ProjectModel.Apple}/>
		</Container>
		
		<div className="project-section">
			<Container>
		    	<Row>
		    		<Col sm={3}>
		    		<img src= "img/project-page/apple/apple-MariaChimishkyan-2.gif" alt="Apple — Maria Chimishkyan 2"/>
		    		<div className="project-caption">Book</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-3.gif" alt="Apple — Maria Chimishkyan 2"/>
					<div className="project-caption">Candle</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-4.gif" alt="Apple — Maria Chimishkyan 3"/>
					<div className="project-caption">Headphones</div>
					</Col>

					<div className="spacer"></div>

					<Col sm={3}>
		    		<img src= "img/project-page/apple/apple-MariaChimishkyan-5.gif" alt="Apple — Maria Chimishkyan 1"/>
		    		<div className="project-caption">Hourglass</div>
					</Col>
				</Row>
				<Row>				
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-6.gif" alt="Apple — Maria Chimishkyan 2"/>
					<div className="project-caption">Key</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-7.gif" alt="Apple — Maria Chimishkyan 3"/>
					<div className="project-caption">Ladder</div>
					</Col>

					<div className="spacer"></div>

					<Col sm={3}>
		    		<img src= "img/project-page/apple/apple-MariaChimishkyan-8.gif" alt="Apple — Maria Chimishkyan 1"/>
		    		<div className="project-caption">Lightbulb</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-9.gif" alt="Apple — Maria Chimishkyan 2"/>
					<div className="project-caption">Link</div>
					</Col>
				</Row>
				<Row>		
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-10.gif" alt="Apple — Maria Chimishkyan 3"/>
					<div className="project-caption">Magnifying Glass</div>
					</Col>

					<div className="spacer"></div>

		    		<Col sm={3}>
		    		<img src= "img/project-page/apple/apple-MariaChimishkyan-11.gif" alt="Apple — Maria Chimishkyan 1"/>
		    		<div className="project-caption">Mic</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-12.gif" alt="Apple — Maria Chimishkyan 2"/>
					<div className="project-caption">Mug</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
					<img src= "img/project-page/apple/apple-MariaChimishkyan-13.gif" alt="Apple — Maria Chimishkyan 3"/>
					<div className="project-caption">Puzzle Pieces</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Apple);
