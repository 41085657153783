import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function AdidasStateSneakers({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.AdidasStateSneakers.title}</title>
	</Helmet>
	
	<div className="inner-wrapper">		
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-masthead.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				</Col>
				
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.AdidasStateSneakers}/>
	    </Container>

		<div className="project-section">
		<Container>
	    	<Row>
				<Col sm={3}>
				</Col>

				{/*<Col sm={6}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-map.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
  </Col>*/}
				
			</Row>
		</Container>
	    </div>
	    
		<div className="project-section">
		<Container>
	    	<Row>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-1-Ultraboost-1.0.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Ultraboost 1.0</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-2-NMD-R1.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">NMD R1</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-3-Samba.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Samba</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-4-Superstar.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Superstar</div>
				</Col>
			</Row>
		</Container>
		</div>
		<div className="project-section">
		<Container>
	    	<Row>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-5-Mahomes-1-Impact-FLX-1.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Mahomes 1 Impact FLX 1</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-6-Adilette-Comfort.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Adilette Comfort</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-7-Swift-Run.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Swift Run</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-8-Crazy-Flight.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Crazy Flight</div>
				</Col>
			</Row>
		</Container>
		</div>

		<div className="project-section">
		<Container>
	    	<Row>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-9-Nova-Flight.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Nova Flight</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-10-Terrex-Free-Hiker.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Terrex Free Hiker</div>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/adidas-statesneakers/adidas-MariaChimishkyan-11-Ultraboost-22.jpg" alt="Adidas - State Sneakers — Maria Chimishkyan 1"/>
				<div className="project-caption">Ultraboost 22</div>
				</Col>
				<Col sm={3}>
				</Col>
			</Row>
		</Container>
		</div>

		
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(AdidasStateSneakers);
