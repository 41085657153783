import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function SpaceNinety8({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.SpaceNinety8.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={3}>
	    		<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-1.gif" alt="Space Ninety 8 - Maria Chimishkyan 1"/>
				</Col>
				<Col sm={3}>
				<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-2.gif" alt="Space Ninety 8 - Maria Chimishkyan 2"/>
				</Col>			
				<Col sm={3}>
				<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-3.gif" alt="Space Ninety 8 - Maria Chimishkyan 3"/>
				</Col>

				<div className="spacer"></div>

				<Col sm={3}>
	    		<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-4.gif" alt="Space Ninety 8 - Maria Chimishkyan 4"/>
				</Col>
			</Row>
			 <ProjectDescription projectModel={ProjectModel.SpaceNinety8}/>
		</Container>
		<div className="project-section">
		<Container>
	
	    	<Row>	
				<Col sm={3}>
				<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-5.gif" alt="Space Ninety 8 - Maria Chimishkyan 5"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={3}>
				<img src= "img/project-page/spaceninety8/spaceninety8-MariaChimishkyan-6.gif" alt="Space Ninety 8 - Maria Chimishkyan 6"/>
				</Col>
			</Row>
		</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(SpaceNinety8);
