import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Atlantic({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.Atlantic.title}</title>
	</Helmet>
		
	<img className="cover" src= "img/project-page/the-atlantic/theatlantic-MariaChimishkyan-0.gif" alt="The Atlantic — Maria Chimishkyan 0"/>
	<Container>
		<ProjectDescription projectModel={ProjectModel.Atlantic}/>
	</Container>
		
		<div className="inner-wrapper">
		
		<div className="project-section" >
		    <Container>
		    	<Row>
		    		<Col sm={3}>
		    			<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/the-atlantic/theatlantic-MariaChimishkyan-1.mp4"
						alt="The Atlantic - Maria Chimishkyan 1"/>
						<div className="project-caption">Best Music of 2021</div>
					</Col>
									
					<Col sm={3}>
						<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/the-atlantic/theatlantic-MariaChimishkyan-3.mp4"
						alt="The Atlantic - Maria Chimishkyan 3"/>	
						<div className="project-caption">Best Films of 2021</div>			
					</Col>
									
					<Col sm={3}>
						<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/the-atlantic/theatlantic-MariaChimishkyan-2.mp4"
						alt="The Atlantic - Maria Chimishkyan 2"/>	
						<div className="project-caption">Best Books of 2021</div>			
					</Col>

					<Col sm={3}>
		    			<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/the-atlantic/theatlantic-MariaChimishkyan-4.mp4"
						alt="The Atlantic - Maria Chimishkyan 1"/>
						<div className="project-caption">Best Podcasts of 2021</div>
					</Col>
				</Row>
			</Container>
		</div>
		{/*<div className="project-section" >
			<Container>
		    	<Row>
		    							
					<Col sm={3}>
						<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/the-atlantic/theatlantic-MariaChimishkyan-5.mp4"
						alt="The Atlantic - Maria Chimishkyan 2"/>	
						<div className="project-caption">Best TV Shows of 2021</div>			
					</Col>
					
									
				</Row>
					</Container>
		</div>*/}
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Atlantic);
