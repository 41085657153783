import React from 'react';
import { Row, Col } from 'react-grid-system';
import '../App.css';

function ProjectDescription(props) {
  return (
	<div className="project-description">
	<Row>			
		{/*<Col sm={12}>
			{props.projectModel.headline.length > 0 && (
			<div className="project-headline">{props.projectModel.headline}</div>
			)}
			</Col>	*/}				
	</Row>	
				
	<Row>
					
		<Col sm={3}>
			<div className="subhead">
			<div className="subhead-white-title">{props.projectModel.projectName}</div> 
			<div className="subhead-white">{props.projectModel.client}</div>
			{/*{props.projectModel.articleLink.length > 0 && (
		    <div className="subhead-white"><a href={props.projectModel.articleLink}>(View Article)</a></div>*/}
			</div>
		
		    )}

			
			
		</Col>
						 
		<Col sm={3}>
			<div className="subhead">
			{props.projectModel.contributorRole.length > 0 && (
				<div className="subhead-white-title">{props.projectModel.contributorRole} <br/>
				<a href={props.projectModel.contributorLink}>{props.projectModel.contributorName}</a></div>	)}
			</div>	

			<div className="subhead">
			{props.projectModel.contributorRole1.length > 0 && (
				<div className="subhead-white">
					{props.projectModel.contributorRole1}: <a href={props.projectModel.contributorLink1}>{props.projectModel.contributorName1}</a>
				</div>
			)}
			</div>	
			
			

			<div className="subhead-white">ROLE <br/> {props.projectModel.role}</div>
		
			
		
			
			
		</Col>

		

		<Col sm={6}>
			{props.projectModel.headline.length > 0 && (
			<div className="project-headline">{props.projectModel.headline}</div>
			)}
			</Col>		
								
		
	</Row>	

{/*	<Row>			
		<Col sm={9}>
			{props.projectModel.headline.length > 0 && (
			<div className="project-headline">{props.projectModel.headline}</div>
			)}
			</Col>		
			</Row>	*/}

	</div>					

  )
}
 
export default ProjectDescription;